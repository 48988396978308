@font-face {
    font-family: "Avenir Black";
    src: url(../assets/fonts/avenir/Avenir-Black.ttf);
}

@font-face {
    font-family: "Avenir Book";
    src: url(../assets/fonts/avenir/Avenir-Book.ttf);
}

@font-face {
    font-family: "Avenir Heavy";
    src: url(../assets/fonts/avenir/Avenir-Heavy.ttf);
}

@font-face {
    font-family: "Avenir Light";
    src: url(../assets/fonts/avenir/Avenir-Light.ttf);
}

@font-face {
    font-family: "Avenir Medium";
    src: url(../assets/fonts/avenir/Avenir-Medium.ttf);
}
  

body, html {
    font-family: "Avenir Heavy", sans-serif;
    height: 100%;
    padding: 0;
    margin: 0;
}

.paper-container {
    padding: 20px;
    margin-bottom: 20px;
}