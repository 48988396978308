#home {
    background-color: #00923F;
    font-family: "Avenir Black", sans-serif;

    color: white;

    #flex-area {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 90vh;

        img {
            width: 480px;
            height: auto;
        }

        #login {
            width: 400px;
            padding: 40px;
            display: flex;
            flex-direction: column;
            align-items: center;

            #loginInputArea {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 300px;
                margin-bottom: 20px;

                .loginInput {
                    margin-top: 10px;
                }
            }
        }
    }

    #footer-area {
        width: 100vw;
        height: 10vh;

        display: flex;
        justify-content: center;
        align-items: center;

        a {
            margin-left: 10px;
            margin-right: 10px;
            color: white;
            text-decoration: none;
        }

        a:hover {
            text-decoration: underline;
        }

    }

    a {
        text-decoration: none;
        font-weight: bold;
        color: inherit;
    }

    a:hover {
        text-decoration: underline;
    }

    h1 {
        margin: 0;
        padding: 0;
    }

    .last {
        padding-bottom: 100px;
    }

    img {
        width: 50vw;
        height: 25vw;
        max-width: 600px;
        max-height: 300px;
        min-width: 300px;
        min-height: 150px;
    }

    #auth-button-container {
        margin-top: 25px;
        margin-bottom: -10px;

        #auth-line {
            margin-left: auto;
            margin-right: auto;
            width: calc(100% - 40px);
            height: 1px;
            background-color: lightgrey;
            margin-bottom: 25px;
        }

        #auth-google {
            margin-right: 10px;
        }
    }
}

@media only screen and (min-width: 600px) {
    #home {
        img {
        }
    }
}

@media only screen and (max-width: 600px) {
    #home {
        img {
            padding-right: 50px;
        }
    }
}