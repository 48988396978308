#send-to-all-container {
    h4 {
        font-size: 16px;
        font-weight: normal;
    }
}

#send-to-one-container {
    margin-top: 50px;
    h4 {
        font-size: 16px;
        font-weight: normal;
    }
}

.entry-container {
    display: flex;
    flex-direction: column;
    margin-top: -20px;

    .notification-button {
        width: 200px;
        margin-top: 20px;
    }
}