#body {
    margin: 30px;
}

#order {
    margin-top: 10px;
    margin-bottom: 10px;
    overflow: auto; 
}

#order-title {
    font-size: 18px;
}

#field {
    overflow: auto; 
    margin-top: 5px;
    display: flex;
    flex-direction: row;
}

#key {
    background-color: orange;
}

#popUpWindow {
    width: 300px;
    height: 200px;
    background-color: violet;

}

#private-company-container {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.company-preview-area {
    margin: 10px;
    padding: 5px 20px;
    border-radius: 5px;
    width: 375px;
    background-color: #F6F7F9;
    cursor: pointer;
    overflow: hidden;

    .company-logo {
        display: flex;
        flex-direction: row;
        justify-content: end;
    }

    img {
        height: 30px;
        margin-top: -35px;
        margin-right: -10px;
        margin-left: auto;
    }

    p {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}