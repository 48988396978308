.promotion {
    background: rgba(223, 230, 233,0.3);
    padding: 15px;
    padding-top: 0px;
    padding-bottom: 15px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px lightgrey;
    margin: 10px;
}

.button_fab {
    font-family: 'Avenir';
    background-color: #00923F;
}

img.promoImage {
    width: 250px;
    box-shadow: 0px 1px 5px lightgrey;
    margin-top: 15px;
}

.buttons {
    text-align: right;
}

h4.title {
    margin: 0;
}

h5.subtitle {
    margin: 0;
    font-weight: lighter;
}

p {
    font-size: 0.75em;
}

.date-selector-area {
    display: flex;
    flex-direction: row;

    .date-selector {
        margin-top: 20px;
        margin-right: 5px;
    }
}

#toggle-button-container {
    margin-top: 25px;
    margin-bottom: 15px;

    .promo-button {
        margin-left: 10px;
    }
}

#suggested-size {
    margin-top: 5px;
    font-size: 24px !important;
}