#body {
    margin: 30px;
}

#order {
    margin-top: 10px;
    margin-bottom: 10px;
    overflow: auto; 
}

#order-title {
    font-size: 18px;
}

#field {
    overflow: auto; 
    margin-top: 5px;
    display: flex;
    flex-direction: row;
}

#key {
    background-color: orange;
}

#popUpWindow {
    width: 300px;
    height: 200px;
    background-color: violet;

}

#order-info-area {
    margin-top: 5px;

    p {
        font-size: 14px;
        margin-top: 0px;
        margin-bottom: 5px;
    }
}

#bottom-button-area {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;

    .submit-button {
        margin-left: 10px;
        margin-right: 10px;
    }
}

#product-image-area {
    display: flex;
    flex-direction: column;
}

.product-area {
    display: flex;
    flex-direction: row;

    .product-text-area {
        :nth-child(1) {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 0px;
        }

        :nth-child(2) {
            margin-top: 5px;
        }

        .product-text-no-top {
            margin-top: -10px;
            margin-bottom: 0px;
        }
    }
}

.product-image {
    width: 100px;
    height: 75px;
}

.no-image {
    width: 100px;
    height: 75px;
    background-color: gray;
}

#print-label-container {
    display: none;
}

#print-label-items {
    margin: 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.print-label {
    margin: 25px;
    padding: 0px 15px;
    width: 286px;
    height: 200px;
    border: 1px solid black;

    .product-text-delivery-date {
        text-align: right;
        margin-right: -8px;
        margin-top: 15px;
    }
}

.print-fridge-name {
    font-weight: normal;
    font-style: italic;
}

.print-label-user {
    margin-left: 15px;
}

.print-label-fridge {
    margin-left: 17px;
    margin-top: -20px;
    font-size: 14px;
    font-weight: normal;
}

#save-delivery-date-button {
    margin-left: 20px;
    margin-top: 30px;
}
