#body {
    margin: 30px;
}

#product {
    margin-top: 10px;
    margin-bottom: 10px;
    overflow: auto; 
}

#product-container {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.product-preview-area {
    margin: 10px;
    padding: 5px 20px;
    border-radius: 5px;
    width: 45%;
    background-color: #F6F7F9;
    cursor: pointer;
}

img.productImage {
    width: 175px;
    box-shadow: 0px 1px 5px darkgrey;
    margin-top: 15px;
}

h5.name {
    margin: 0;
}

.price {
    margin: 0;
    font-size: 1em;
}

#checkbox-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

#product-title {
    font-size: 18px;
}

#field {
    overflow: auto; 
    margin-top: 5px;
    display: flex;
    flex-direction: row;
}

#key {
    background-color: orange;
}

#popUpWindow {
    width: 300px;
    height: 200px;
}